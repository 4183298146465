/**
 * 腾讯云COS文件上传
 */
import { message } from 'antd';
import { getNameByIdCard } from '@/services/mediation';
import ResponseCode from '@/enum/ResponseCode';
import request, { BaseResponse } from './request';

const SLICE_SIZE = 4 * 1024 * 1024;

/**
 * 把文件分片
 * @param file
 */
function cutFileSplice(file: File) {
  const pieces = [];
  const total = Math.ceil(file.size / SLICE_SIZE);

  for (let i = 0; i < total; i++) {
    pieces.push(file.slice(
      i * SLICE_SIZE,
      (i + 1) * SLICE_SIZE,
    ));
  }

  return pieces;
}

/**
 * 上传某一片数据
 * @param part
 * @param partNum 
 * @param uploadId
 * @param isLast
 */
async function uploadSplicePart(part: any, partNum: number, uploadId: string, isLast: boolean) {
  const formData = new FormData();
  formData.append('uploadingFile', part);
  formData.append('uploadId', uploadId);
  formData.append('partNum', String(partNum));
  formData.append('isLastPart', String(isLast));

  const res = await request('/api/file/partUpload', {
    method: 'post',
    body: formData,
  });

  if (!res || res.errCode !== ResponseCode.SUCCESS) {
    throw new Error(res?.errMsg || '文件分片上传失败');
  }
}

/**
 * 初始化分片上传
 * @param fileName
 * @param fileSize
 * @param caseNo
 */
function initSpliceUpload(fileName: string, fileSize: number, caseNo?: string) {
  const formData = new FormData();
  formData.append('fileName', fileName);
  formData.append('fileSize', String(fileSize));

  if (caseNo) {
    formData.append('caseNo', caseNo);
  }

  return request('/api/file/initFilePartUpload', {
    method: 'POST',
    body: formData,
  })
}

/**
 * 完成上传
 * @param uploadId
 */
function finishSpliceUpload(uploadId: string) {
  const formData = new FormData();
  formData.append('uploadId', uploadId);

  return request('/api/file/finishPartUpload', {
    method: 'post',
    body: formData,
  });
}

/**
 * 已经上传成功的文件
 * 避免上传多个文件的时候某些文件失败造成重复上传
 */
const UPLOADED = new WeakMap<File, string>();

export function uploadCos_tenxun(file: File[]): Promise<string[] | undefined>;
export function uploadCos_tenxun(file: File): Promise<string | undefined>;
export async function uploadCos_tenxun(file: File | File[]): Promise<undefined | string | string[]> {
  if (file instanceof Array) {
    const fileIds: string[] = [];
    for (let i = 0; i < file.length; i++) {
      if (UPLOADED.has(file[i])) {
        fileIds.push(UPLOADED.get(file[i])!);
        continue;
      }

      const fileId = await uploadCos_tenxun(file[i]);

      if (fileId) {
        UPLOADED.set(file[i], fileId);
        fileIds.push(fileId);
      } else {
        return;
      }
    }

    return fileIds;
  }

  if (file.size >= SLICE_SIZE) {
    // 超出限制则走分片上传的流程
    const res = await initSpliceUpload(file.name, file.size);

    if (!res || res.errCode !== ResponseCode.SUCCESS) {
      message.error(res?.errMsg || '文件上传失败');
  
      return;
    }

    const { uploadId } = JSON.parse(res.data);

    if (!uploadId) {
      message.error('初始化上传失败');

      return;
    }

    const pieces = cutFileSplice(file);

    try {
      for (let i = 0; i < pieces.length; i++) {
        await uploadSplicePart(pieces[i], i + 1, uploadId, i === pieces.length - 1);
      }

      const res = await finishSpliceUpload(uploadId);

      if (!res || res.errCode !== ResponseCode.SUCCESS) {
        message.error(res?.errMsg || '文件上传失败');

        return;
      }

      const { fileId } = JSON.parse(res.data);

      if (!fileId) {
        message.error('文件上传失败');

        return;
      }

      return fileId;
    } catch (err: any) {
      message.error(err?.message || '文件上传失败');
      return;
    }
  }

  const formData = new FormData();
  formData.append('file', file);

  const res = await request<BaseResponse<{ fileId: string }>>('/api/file/uploadFileToCosGetUrls', {
    body: formData,
    method: 'POST',
  });

  if (!res || res.errCode !== ResponseCode.SUCCESS) {
    message.error(res?.errMsg || '文件上传失败');
  }

  return res?.data?.fileId;
}

// 身份证识别姓名身，份证号
export function getByIdCard(imageUrls: string, token: string) {
  return new Promise(((resolve, reject) => {
    // @ts-ignore
    getNameByIdCard(imageUrls, token).then(response => {
      if (response.errCode === ResponseCode.SUCCESS) {
        resolve(response.data);
      } else {
        reject(response.errMsg);
      }
    });
  }))
}
