import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from 'D:/projects-xhst/cnsstj-web/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/user',
    routes: [
      {
        name: 'login_dsy',
        path: '/user/login_dsy',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__user__login_dsy" */ '../user/login_dsy'),
              LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                .default,
            })
          : require('../user/login_dsy').default,
        exact: true,
      },
      {
        name: 'login',
        path: '/user/login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__user__login" */ '../user/login'),
              LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                .default,
            })
          : require('../user/login').default,
        exact: true,
      },
      {
        name: 'register',
        path: '/user/register',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__user__register" */ '../user/register'),
              LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                .default,
            })
          : require('../user/register').default,
        exact: true,
      },
    ],
  },
  {
    path: '/deliver',
    routes: [
      {
        name: 'deliever_files',
        path: '/deliver/:id',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__deliver__index" */ '../deliver/index'),
              LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                .default,
            })
          : require('../deliver/index').default,
        exact: true,
      },
    ],
  },
  {
    path: '/home',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__SecurityLayout" */ '../../layouts/SecurityLayout'),
          LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/SecurityLayout').default,
    routes: [
      {
        path: '/home',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
              LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                .default,
            })
          : require('../../layouts/BasicLayout').default,
        routes: [
          {
            path: '/home',
            redirect: '/home/caseManagement/lists',
            exact: true,
          },
          {
            path: '/home/index',
            name: 'index',
            icon: 'setting',
            routes: [
              {
                path: '/home/index/InformationPortal',
                name: 'InformationPortal',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__index__InformationPortal__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/index/InformationPortal/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../index/InformationPortal'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../index/InformationPortal').default,
                exact: true,
              },
              {
                path: '/home/index/moreInfo/:type',
                name: 'moreInfo',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__index__moreInfo__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/index/moreInfo/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../index/moreInfo'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../index/moreInfo').default,
                exact: true,
              },
              {
                path: '/home/index/details/:id',
                name: 'details',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__index__details__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/index/details/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../index/details'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../index/details').default,
                exact: true,
              },
            ],
          },
          {
            path: '/home/returnMoneyManagement',
            name: 'returnMoneyManagement',
            icon: 'setting',
            routes: [
              {
                path: '/home/returnMoneyManagement/caseList',
                name: 'caseList',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__ReturnMoney__CaseList__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/ReturnMoney/CaseList/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../ReturnMoney/CaseList'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../ReturnMoney/CaseList').default,
                exact: true,
              },
              {
                path: '/home/returnMoneyManagement/notarizationList',
                name: 'notarizationList',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__ReturnMoney__NotarizationList__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/ReturnMoney/NotarizationList/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../ReturnMoney/NotarizationList'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../ReturnMoney/NotarizationList').default,
                exact: true,
              },
              {
                path: '/home/returnMoneyManagement/caseDetail',
                name: 'caseDetail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__ReturnMoney__CaseDetail__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/ReturnMoney/CaseDetail/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../ReturnMoney/CaseDetail'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../ReturnMoney/CaseDetail').default,
                exact: true,
              },
              {
                path: '/home/returnMoneyManagement/overdueCaseList',
                name: 'overdueCaseList',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__ReturnMoney__OverdueCaseList__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/ReturnMoney/OverdueCaseList/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../ReturnMoney/OverdueCaseList'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../ReturnMoney/OverdueCaseList').default,
                exact: true,
              },
              {
                path: '/home/returnMoneyManagement/index',
                name: 'index',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__ReturnMoney__Index__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/ReturnMoney/Index/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../ReturnMoney/Index'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../ReturnMoney/Index').default,
                exact: true,
              },
            ],
          },
          {
            path: '/home/caseEntry',
            name: 'caseEntry',
            icon: 'highlight',
            routes: [
              {
                path: '/home/caseEntry/single/:type',
                name: 'single',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__CaseEntry__SingleEntry__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/CaseEntry/SingleEntry/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseEntry/SingleEntry'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseEntry/SingleEntry').default,
                exact: true,
              },
              {
                path: '/home/caseEntry/batch',
                name: 'batch',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseEntry/BatchEntry'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseEntry/BatchEntry').default,
                exact: true,
              },
              {
                path: '/home/caseEntry/reBatch',
                name: 'reBatch',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseEntry/ReBatchEntry'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseEntry/ReBatchEntry').default,
                exact: true,
              },
              {
                path: '/home/CaseEntry/BatchEntryRecord',
                name: 'single',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseEntry/BatchEntryRecord'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseEntry/BatchEntryRecord').default,
                exact: true,
              },
              {
                path: '/home/caseEntry/saveResult/:id',
                hideInMenu: true,
                name: 'saveResult',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__CaseEntry__SingleEntry__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/CaseEntry/SingleEntry/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseEntry/SingleEntry/SaveResult'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseEntry/SingleEntry/SaveResult').default,
                exact: true,
              },
            ],
          },
          {
            path: '/home/caseManagement',
            name: 'caseManagement',
            icon: 'unordered-list',
            routes: [
              {
                path: '/home/caseManagement/list',
                name: 'list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__CaseManagement__CaseList__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/CaseManagement/CaseList/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseManagement/CaseList'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseManagement/CaseList').default,
                exact: true,
              },
              {
                path: '/home/caseManagement/lists',
                name: 'lists',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__CaseManagement__CaseLists__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/CaseManagement/CaseLists/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseManagement/CaseLists'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseManagement/CaseLists').default,
                exact: true,
              },
              {
                path: '/home/caseManagement/CaseReturnList',
                name: 'CaseReturnList',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseManagement/CaseReturnList'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseManagement/CaseReturnList').default,
                exact: true,
              },
              {
                path: '/home/caseManagement/CaseLyLists',
                name: 'lyLists',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseManagement/CaseLyLists'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseManagement/CaseLyLists').default,
                exact: true,
              },
              {
                path: '/home/caseManagement/initCheckList',
                name: 'initCheckList',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__CaseManagement__InitCheckList__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/CaseManagement/InitCheckList/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseManagement/InitCheckList'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseManagement/InitCheckList').default,
                exact: true,
              },
              {
                path: '/home/caseManagement/stayOutInspection',
                name: 'stayOutInspection',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__CaseManagement__StayOutInspection__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/CaseManagement/StayOutInspection/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseManagement/StayOutInspection'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseManagement/StayOutInspection').default,
                exact: true,
              },
              {
                path: '/home/caseManagement/mediatorList',
                name: 'mediatorList',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__CaseManagement__MediatorList__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/CaseManagement/MediatorList/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseManagement/MediatorList'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseManagement/MediatorList').default,
                exact: true,
              },
              {
                path: '/home/caseManagement/mediationNotifyList',
                name: 'mediationNotifyList',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__CaseManagement__MediationNotifyList__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/CaseManagement/MediationNotifyList/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseManagement/MediationNotifyList'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseManagement/MediationNotifyList').default,
                exact: true,
              },
              {
                path: '/home/caseManagement/mediationList',
                name: 'mediationList',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__CaseManagement__MediationList__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/CaseManagement/MediationList/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseManagement/MediationList'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseManagement/MediationList').default,
                exact: true,
              },
              {
                path: '/home/caseManagement/docGenerateList',
                name: 'docGenerateList',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__CaseManagement__DocGenerateList__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/CaseManagement/DocGenerateList/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseManagement/DocGenerateList'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseManagement/DocGenerateList').default,
                exact: true,
              },
              {
                path: '/home/caseManagement/entry',
                hideInMenu: true,
                name: 'single',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__CaseEntry__SingleEntry__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/CaseEntry/SingleEntry/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseEntry/SingleEntry'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseEntry/SingleEntry').default,
                exact: true,
              },
              {
                path: '/home/caseManagement/profile/:id',
                hideInMenu: true,
                name: 'profile',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__CaseManagement__CaseProfile__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/CaseManagement/CaseProfile/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseManagement/CaseProfile'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseManagement/CaseProfile').default,
                exact: true,
              },
              {
                path: '/home/caseManagement/mediationRecord',
                hideInMenu: true,
                name: 'mediationRecord',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__CaseManagement__MediationRecordProfile__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/CaseManagement/MediationRecordProfile/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseManagement/MediationRecordProfile'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseManagement/MediationRecordProfile').default,
                exact: true,
              },
              {
                path: '/home/caseManagement/mediationRecord/result',
                hideInMenu: true,
                name: 'mediationRecordSaveResult',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__CaseManagement__MediationRecordProfile__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/CaseManagement/MediationRecordProfile/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseManagement/MediationRecordProfile/SaveResult'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseManagement/MediationRecordProfile/SaveResult')
                      .default,
                exact: true,
              },
              {
                path: '/home/caseManagement/docProfile/:id',
                hideInMenu: true,
                name: 'docProfile',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__CaseManagement__DocProfile__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/CaseManagement/DocProfile/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseManagement/DocProfile'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseManagement/DocProfile').default,
                exact: true,
              },
              {
                path: '/home/caseManagement/phoneDialingBox/:id/:call',
                hideInMenu: true,
                name: 'phoneDialingBox',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__CaseManagement__PhoneDialingBox__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/CaseManagement/PhoneDialingBox/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseManagement/PhoneDialingBox'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseManagement/PhoneDialingBox').default,
                exact: true,
              },
              {
                path: '/home/caseManagement/keyComplaint/:id',
                hideInMenu: true,
                name: 'keyComplaint',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__CaseManagement__KeyComplaint__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/CaseManagement/KeyComplaint/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CaseManagement/KeyComplaint'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CaseManagement/KeyComplaint').default,
                exact: true,
              },
            ],
          },
          {
            path: '/home/mediate',
            name: 'mediate',
            icon: 'bar-chart',
            routes: [
              {
                path: '/home/mediate/paperManagement',
                name: 'paperManagement',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Mediate/PaperManagement'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Mediate/PaperManagement').default,
                exact: true,
              },
            ],
          },
          {
            path: '/home/CheckMediationRecord',
            name: 'CheckMediationRecord',
            icon: 'bar-chart',
            routes: [
              {
                path: '/home/CheckMediationRecord/mediationRecordList',
                name: 'mediationRecordList',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../CheckMediationRecord/mediationRecordList'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../CheckMediationRecord/mediationRecordList')
                      .default,
                exact: true,
              },
            ],
          },
          {
            path: '/home/wtfk',
            name: 'wtfk',
            icon: 'bar-chart',
            routes: [
              {
                path: '/home/wtfk/wtfkList',
                name: 'wtfkList',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../wtfk/wtfkList'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../wtfk/wtfkList').default,
                exact: true,
              },
              {
                path: '/home/wtfk/details/:id',
                name: 'WtfkDetails',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__wtfk__details__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/wtfk/details/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../wtfk/details'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../wtfk/details').default,
                exact: true,
              },
            ],
          },
          {
            path: '/home/data',
            name: 'data',
            icon: 'bar-chart',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__DataAnalysis__model.tsx' */ 'D:/projects-xhst/cnsstj-web/src/pages/DataAnalysis/model.tsx').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__DataAnalysis" */ '../DataAnalysis'),
                  LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../DataAnalysis').default,
            exact: true,
          },
          {
            path: '/home/DataAnalysisPdd',
            name: 'DataAnalysisPdd',
            icon: 'bar-chart',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__DataAnalysisPdd__model.tsx' */ 'D:/projects-xhst/cnsstj-web/src/pages/DataAnalysisPdd/model.tsx').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__DataAnalysisPdd" */ '../DataAnalysisPdd'),
                  LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../DataAnalysisPdd').default,
            exact: true,
          },
          {
            path: '/home/statistics',
            name: 'data',
            icon: 'bar-chart',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Performance" */ '../Performance'),
                  LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Performance').default,
            exact: true,
          },
          {
            path: '/home/system',
            name: 'system',
            icon: 'setting',
            routes: [
              {
                path: '/home/system/userList',
                name: 'userList',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__System__UserList__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/System/UserList/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/UserList'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/UserList').default,
                exact: true,
              },
              {
                path: '/home/system/auditList',
                name: 'auditList',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/AuditList'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/AuditList').default,
                exact: true,
              },
              {
                path: '/home/system/smsTemplateList',
                name: 'smsTemplateList',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__System__SmsTemplateList__model.ts' */ 'D:/projects-xhst/cnsstj-web/src/pages/System/SmsTemplateList/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/SmsTemplateList'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/SmsTemplateList').default,
                exact: true,
              },
            ],
          },
          {
            path: '/home/privateInfo',
            name: 'privateInfo',
            icon: 'bar-chart',
            routes: [
              {
                path: '/home/privateInfo/loginUserInfo',
                name: 'loginUserInfo',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../privateInfo/loginUserInfo'),
                      LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../privateInfo/loginUserInfo').default,
                exact: true,
              },
            ],
          },
          {
            name: '403',
            icon: 'smile',
            hideInMenu: true,
            path: '/home/exception403',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Exception403" */ '../Exception403'),
                  LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception403').default,
            exact: true,
          },
          {
            name: '404',
            icon: 'smile',
            hideInMenu: true,
            path: '/home/exception404',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Exception404" */ '../Exception404'),
                  LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception404').default,
            exact: true,
          },
          {
            name: '500',
            icon: 'smile',
            hideInMenu: true,
            path: '/home/exception500',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Exception500" */ '../Exception500'),
                  LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception500').default,
            exact: true,
          },
          {
            redirect: '/home/exception404',
            exact: true,
          },
        ],
      },
      {
        redirect: '/home/exception404',
        exact: true,
      },
    ],
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "www__layout" */ '../../www/layout'),
          LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
            .default,
        })
      : require('../../www/layout').default,
    routes: [
      {
        name: 'www_platform',
        path: '/platform',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "www__platform" */ '../../www/platform'),
              LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                .default,
            })
          : require('../../www/platform').default,
        exact: true,
      },
      {
        name: 'www_tjzx_detail',
        path: '/tjzx/:name',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "www__tjzxDetail" */ '../../www/tjzxDetail'),
              LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                .default,
            })
          : require('../../www/tjzxDetail').default,
        exact: true,
      },
      {
        name: 'www_tjzx',
        path: '/tjzx',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "www__tjzx" */ '../../www/tjzx'),
              LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                .default,
            })
          : require('../../www/tjzx').default,
        exact: true,
      },
      {
        name: 'www_tjy',
        path: '/tjy',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "www__tjy" */ '../../www/tjy'),
              LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                .default,
            })
          : require('../../www/tjy').default,
        exact: true,
      },
      {
        name: 'www_home',
        path: '/',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "www" */ '../../www'),
              LoadingComponent: require('D:/projects-xhst/cnsstj-web/src/components/PageLoading/index')
                .default,
            })
          : require('../../www').default,
        exact: true,
      },
    ],
  },
  {
    redirect: '/home/exception404',
    exact: true,
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
