import request from '@/utils/request';

export interface LoginParamsType {
  userName: string;
  password: string;
  mobile: string;
  captcha: string;
}

/** @deprecated 不知道为啥叫这名，废弃掉用loginByPwd */
export async function fakeAccountLogin(params: LoginParamsType) {
  return request('/api/login', {
    method: 'POST',
    data: params,
  });
}

export enum LoginRoleType {
  DSY = 1,
  STAFF = 2,
}

export interface LoginByPwdParams {
  loginName: string;
  loginPwd: string;
  picCode: string;
  uid: string;
  autoLogin: boolean;
}

/**
 * 使用密码登录
 * @param data
 * @param type 登录类型
 * @returns
 */
export function loginByPassword(data: LoginByPwdParams, loginType: LoginRoleType) {
  return request('/api/login', {
    method: 'POST',
    data: {
      ...data,
      loginType,
    },
  })
}

export interface LoginByPhoneParams {
  phoneValue: string;
  smsValue: string;
}

export async function loginByPhone(params: LoginByPhoneParams) {
  return request('/api/login/loginByPhone', {
    method: 'POST',
    data: params,
  });
}
export async function getRandomCode() {
  return request('/api/login/getRandomCode', {
    method: 'POST',
  });
}

export async function getFakeCaptcha(mobile: string) {
  return request(`/api/login/captcha?mobile=${mobile}`);
}

/**
 * 当事人注册
 * @param params 查询条件
 */
export async function userRegister(params: any) {
  return request('/api/login/userRegister', {
    method: 'POST',
    data: params,
  });
}

export enum CaseType {
  REGISTER = 1,
  LOGIN = 2,
  MOFIFY_PASSWORD = 3,
}

/**
 * 获取短信验证码
 * @param phone
 */
export async function getSmsCode(phone: string, caseType: CaseType) {
  return request(`/api/login/getSmsCode?phone=${phone}&caseType=${caseType}`, {
    method: 'get',
  });
}

/**
 * 校验短信验证码
 * @param phone
 * @param code
 * @param caseType
 */
export async function checkSmsCode(phone: string, code: string, caseType: CaseType) {
  return request('/api/login/checkSmsCode', {
    method: 'get',
    params: { phone, code, caseType },
  });
}

/**
 * 修改密码
 * @param params
 */
export async function uptPwd(params: any) {
  return request('/api/login/uptPwd', {
    method: 'POST',
    data: params,
  });
}

/**
 * 退出登录
 */
export function logout() {
  return request('/api/login/logout', {
    method: 'POST',
  });
}
