import React from 'react';
import request, { BaseResponse } from '@/utils/request';
import { uploadCos_tenxun } from '@/utils/tenxcos';
import { Modal } from 'antd';
import { CopyrightWorkInfoVO, InfringingGoodsInfo, MediationInfo, TMOwnershipInfo } from './mediation.typing';
import ResponseCode from '@/enum/ResponseCode';
import type { EvidenceFile } from './typing.d';

export interface MediationParam {
  /**
   * 调解ID
   */
  mediationId?: number;

  /**
   * 是否适宜调解, 0：不适宜，1：适宜
   */
  suitableType?: number;

  /**
   * 不适宜调解原因
   */
  noSuitableReason?: number;

  /**
   * 智法运营
   */
  operator?: number;

  /**
   * 调解员
   */
  mediator?: number;

  /**
   * 申请人调解方案
   */
  applicantPlan?: string;

  /**
   * 被申请人调解方案
   */
  respondentPlan?: string;

  /**
   * 初步调解方案
   */
  preliminaryPlan?: string;

  /**
   * 调解时间
   */
  mediationDate?: string;

  /**
   * 调解地点
   */
  mediationAddr?: string;

  /**
   * 是否同意调解
   */
  isAggre?: string;

  /**
   * 沟通对象Id列
   */
  communicationPerson?: string;

  /**
   * 沟通对象name列
   */
  communicationPersonName?: string;

  /**
   * 备注
   */
  remark?: string;
}

export async function queryMediation(params: any) {
  return request(`/api/mediation/get/${params.id}`);
}
export async function queryMediationById(id: any) {
  return request<BaseResponse<MediationInfo>>(`/api/mediation/get/${id}`);
}
export async function mediationUpdate(params: any) {
  return request('/api/mediation/update', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function suitableMediation(params: MediationParam) {
  return request('/api/mediation/suitable', {
    method: 'POST',
    data: params,
  });
}

export async function designate(params: MediationParam) {
  return request('/api/mediation/designate', {
    method: 'POST',
    data: params,
  });
}
export async function designateOrg(params: MediationParam) {
  return request('/api/mediation/designateOrg', {
    method: 'POST',
    data: params,
  });
}
export async function savePlan(params: MediationParam) {
  return request('/api/mediation/savePlan', {
    method: 'POST',
    data: params,
  });
}

export async function saveMediationAddr(params: MediationParam) {
  return request('/api/mediation/addr/add', {
    method: 'POST',
    data: params,
  });
}

export async function queryMediationAddr(params: any) {
  return request(`/api/mediation/addr/query/${params.id}`);
}

export async function uploadBatchMediation(type: any, file: any) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);
  return request('/api/mediation/upload', {
    method: 'POST',
    data: formData,
  });
}

export async function uploadAddCase(type: any, file: any) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);
  return request('/api/mediation/uploadAddCase', {
    method: 'POST',
    data: formData,
  });
}

export async function reUploadBatchMediation(type: any, file: any) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);
  return request('/api/mediation/reUpload', {
    method: 'POST',
    data: formData,
  });
}

export async function mediationLogList(params: any) {
  return request('/api/mediation/log/list', {
    method: 'POST',
    data: params,
  });
}

export async function mediationInfoList(params: any) {
  return request('/api/mediation/record/page/list', {
    method: 'POST',
    data: params,
  });
}

export async function plaintiffAndDefendantList(mediationId: any) {
  const formData = new FormData();
  formData.append('mediationId', mediationId);
  return request('/api/mediation/record/page/plaintiffAndDefendantList', {
    method: 'POST',
    data: formData,
  });
}

export async function smsPhoneList(params: any) {
  return request(`/api/applicant/phone/list/${params.id}`);
}

export async function smsSend(params: any) {
  return request('/api/sms/send', {
    method: 'POST',
    data: params,
  });
}

export async function download(params: any) {
  return request(`/api/mediation/download/${params.id}`, {
    method: 'POST',
    data: params,
  });
}

export async function updateMediationRecord(params: any) {
  return request(`/api/mediation/updateMediationRecord/${params.id}`, {
    method: 'POST',
    data: params,
  });
}

// 编辑前查询接口
export async function getEdit(params: any) {
  return request(`/api/mediation/edit/${params}`);
}


// 编辑当事人前查询接口
export async function findByApplicantId(params: any) {
  return request(`/api/applicant/findByApplicantId?applicantId=${params}`);
}

// 编辑代理人前查询接口
export async function findAgentById(params: any) {
  return request(`/api/lawAgent/findAgentById?agentId=${params}`);
}

// 案件信息编辑接口
export async function editMediation(params: any) {
  return request('/api/mediation/editMediation', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
// 新增反馈问题接口
export async function saveWtfk(params: any) {
  return request('/api/lawWtfk/saveWtfk', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function uploadEvidenceFileOne(
  files: Array<File | string>,
  id: string | number | null,
  resCode: any,
  mediationId: any,
) {
  const fileIds: Array<string> = [];
  const fileIdNames: Record<string, string> = {};
  for (let i = 0; i < files.length; i++) {
    // 如果是字符串说明已经上传过了
    if (typeof files[i] === 'string') {
      fileIds[i] = files[i] as string;
      // eslint-disable-next-line no-continue
      continue;
    }

    // eslint-disable-next-line no-await-in-loop
    const fileId = await uploadCos_tenxun(files[i] as File);

    if (!fileId) {
      throw new Error('上传失败');
    }

    fileIdNames[fileId] = (files[i] as File).name;
    fileIds[i] = fileId;
  }

  const res = await request('/api/mediation/uploadEvidenceFiles', {
    method: 'POST',
    data: {
      id,
      resCode,
      mediationId,
      fileIds,
    },
  });

  // 如果报错则直接提示
  if (res.errCode !== ResponseCode.SUCCESS) {
    return res;
  }

  // 如果存在同名文件，则需要让用户二次确认
  if (res.data.unprocessedSameFileIds?.length) {
    return new Promise((resolve) => {
      Modal.confirm({
        title: '目标位置已经存在同名文件',
        content: (
          <>
            {res.data.unprocessedSameFileIds.map((id: string) => (
              <p key={id}>{fileIdNames[id]}</p>
            ))}
          </>
        ),
        cancelText: '跳过这些文件',
        okText: '覆盖这些文件',
        onCancel: () => resolve(res),
        onOk: async () => {
          const ret = await request('/api/mediation/uploadEvidenceFiles', {
            method: 'POST',
            data: {
              id,
              resCode,
              mediationId,
              fileIds: res.data.unprocessedSameFileIds,
              fileAction: 2,
            },
          });

          resolve(ret);
        },
      })
    });
  }

  return res;
}

export async function getTreeData(caseNo: any, mediationId: any) {
  const formData = new FormData();
  formData.append('caseNo', caseNo);
  formData.append('mediationId', mediationId);
  return request('/api/mediation/getTreeData', {
    method: 'POST',
    data: formData,
  });
}

export async function queryFeedBack(mediationId: any) {
  const formData = new FormData();
  formData.append('mediationId', mediationId);
  return request('/api/mediation/queryFeedBack', {
    method: 'POST',
    data: formData,
  });
}

export async function findPlan(mediationId: any, lawType: any) {
  const formData = new FormData();
  formData.append('mediationId', mediationId);
  formData.append('lawType', lawType);
  return request('/api/mediation/findPlan', {
    method: 'POST',
    data: formData,
  });
}

export async function getcallAndSmsRecordList(mediationId: any) {
  const formData = new FormData();
  formData.append('mediationId', mediationId);
  return request('/api/mediation/getcallAndSmsRecordList', {
    method: 'POST',
    data: formData,
  });
}

export async function loadFeedBack(mediationId: any) {
  const formData = new FormData();
  formData.append('mediationId', mediationId);
  request('/api/mediation/loadFeedBack', {
    method: 'POST',
    data: formData,
  });
}

// 校验
export async function checkFeedBack(mediationId: any) {
  const formData = new FormData();
  formData.append('mediationId', mediationId);
  return request('/api/mediation/checkFeedBack', {
    method: 'POST',
    data: formData,
  });
}

// 校验所选案件是否属于相同组织
export async function checkOrgId(mediationIds: any) {
  const formData = new FormData();
  formData.append('mediationIds', mediationIds);
  return request('/api/mediation/checkOrgId', {
    method: 'POST',
    data: formData,
  });
}

// 查询纸质材料
export async function queryPaperMaterialPage(mediationQuery: any) {
  return request('/api/paper/material/page/queryPaperMaterialPage', {
    method: 'POST',
    data: mediationQuery,
  });
}

// 查询案件退回列表
export async function getCaseBackList(mediationQuery: any) {
  return request('/api/caseBack/getCaseBackList', {
    method: 'POST',
    data: mediationQuery,
  });
}

// 查询批量录入记录
export async function getUploadRecord(query: any) {
  return request('/api/caseBack/getUploadRecord', {
    method: 'POST',
    data: query,
  });
}
// 查询案件外呼信息
export async function getCallRecordLyList(mediationQuery: any) {
  return request('/api/callRecord/getCallRecordLyList', {
    method: 'POST',
    data: mediationQuery,
  });
}
// 查询反馈列表
export async function getWtfkList(LawWtfkQuery: any) {
  return request('/api/lawWtfk/page/query', {
    method: 'POST',
    data: LawWtfkQuery,
  });
}

// 查询调解结果审核列表
export async function findRecordShList(MediationRecordQuery: any) {
  return request('/api/mediation/record/findRecordShList', {
    method: 'POST',
    data: MediationRecordQuery,
  });
}
// 调解结果审核
export async function checkMediationRecord(params: any) {
  return request('/api/mediation/record/checkMediationRecord', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
// 检查导出
export async function checkExportZip() {
  return request('/api/callRecord/checkExportZip', {
    method: 'GET',
  });
}
// 修改纸质材料状态
export async function updatePaperStatus(paperMaterial: any) {
  return request('/api/paper/material/updatePaperStatus', {
    method: 'POST',
    data: paperMaterial,
  });
}

/**
 * 下载反馈表时修改是否移回法院
 *
 * @param params 案件信息
 */
export async function updateIsReturnToCourt(params: any) {
  return request('/api/mediation/updateIsReturnToCourt', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 校验纸质状态
export async function checkPaperStatus(paperMaterial: any) {
  console.log(paperMaterial)
  return request('/api/paper/material/checkPaperStatus', {
    method: 'POST',
    data: paperMaterial,
  });
}

export async function getDirFile(parentId: any, dirFlag: any) {
  const formData = new FormData();
  formData.append('parentId', parentId);
  formData.append('dirFlag', dirFlag);
  return request<BaseResponse<EvidenceFile[]>>('/api/mediation/getDirFile', {
    method: 'POST',
    data: formData,
  });
}

export async function getFilePreviewUrl(key: any) {
  const formData = new FormData();
  formData.append('key', key);
  return request('/api/file/getFilePreviewUrl', {
    method: 'POST',
    data: formData,
  });
}


/**
 * 查询字典列表
 *
 * @param params 查询条件
 */
export async function querySysCodeList(params: any) {
  return request('/api/sysCode/list/query', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}


// 修改案件节点
export async function updateCaseNode(params: any) {
  return request('/api/mediation/updateCaseNode', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 查询操作日志
export async function queryOperationLogPage(mediationId: any, page: any, size: any) {
  const formData = new FormData();
  formData.append('mediationId', mediationId);
  formData.append('page', page);
  formData.append('size', size);
  return request('/api/operation/log/queryOperationLogPage', {
    method: 'POST',
    data: formData,
  });
}
// 修改电话号码
export async function updatePhoneById(params: any) {
  return request('/api/mediation/updatePhoneById', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function findByOrgId(mediationId: any) {
  return request(`/api/document/template/findByOrgId?mediationId=${mediationId}`);
}

// 根据调解案件id查找预收案 案件
export async function findCaseByCaseId(mediationId: any) {
  const formData = new FormData();
  formData.append('mediationId', mediationId);
  return request('/api/mediation/findCaseByCaseId', {
    method: 'POST',
    data: formData,
  });
}

// 提交办结
export async function addCaseFinish(params: any) {
  return request('/api/mediation/addCaseFinish', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 新增 编辑当事人
export async function insertOrUpdate(params: any) {
  return request('/api/applicant/insertOrUpdate', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 新增 编辑代理人
export async function addAndUptAgent(params: any) {
  return request('/api/lawAgent/addAndUptAgent', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 根据调解id查找预收案办结记录
export async function findCaseFinishByCaseId(mediationId: any) {
  const formData = new FormData();
  formData.append('mediationId', mediationId);
  return request('/api/mediation/findCaseFinishByCaseId', {
    method: 'POST',
    data: formData,
  });
}

export type SystemCode = {
  id: number;
  groupId: number;
  codeTypeName: string;
  codeValueName: string;
  codeValueCode: string;
}

// 根据type查找sysCode
export async function findSysCodeByType(type: any): Promise<BaseResponse<{
  data: SystemCode[];
}>> {
  const formData = new FormData();
  formData.append('type', type);
  return request('/api/mediation/findSysCodeByType', {
    method: 'POST',
    data: formData,
  });
}

// 查组织信息
export async function getOrgInfo(isAll: any, orgLevel?: number) {
  const formData = new FormData();
  formData.append('isAll', isAll);

  if (typeof orgLevel === 'number') {
    formData.append('orgLevel', String(orgLevel));
  }

  return request('/api/mediation/getOrgInfo', {
    method: 'POST',
    data: formData,
  });
}
/**
 * 查询已选案件
 */
export async function getFindSelectedCase(paperMaterial: any) {
  return request(`/api/mediation/findSelectedCase?ids=${paperMaterial}`, {
    method: 'POST',
  });
}

/**
 * 删除证据材料清单
 */
export async function getCaseData(paperMaterial: any) {
  return request(`/api/tjhxCase/getCaseData?id=${paperMaterial}`, {
    method: 'POST',
  });
}

/**
 * 删除证据材料清单
 */
export async function delDirectory(paperMaterial: any) {
  return request(`/api/mediation/delDirectory?id=${paperMaterial}`, {
    method: 'POST',
  });
}


/**
 * 立案登记
 */
export async function batchFilingOrTransferCase(params: any) {
  return request('/api/mediation/batchFilingOrTransferCase', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

/**
 * 批量修复
 */
export async function caseBack(params: any) {
  return request('/api/caseBack/caseBack', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

/**
 * 批量修复
 */
export async function batchRepair(params: any) {
  return request('/api/repair/batchRepair', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

/*
*批量导出之前查询接口
*/
export async function checkExportExcel(params: any) {
  return request('/api/mediation/checkExportExcel', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
/*
*批量导出之前查询接口-江苏银行
*/
export async function checkExportExcelJsyh(params: any) {
  return request('/api/mediation/checkExportExcelJsyh', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
/*
*批量导出生成zip
*/
export async function getLawExportZipInfo() {
  return request('/api/mediation/getLawExportZipInfo', {
    method: 'POST',
  });
}
/*
*批量导出
*/
export async function exportExcelThree(params: any) {
  return request('/api/mediation/exportExcelThree', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
/**
 * 批量导出前端获取数据接口
 */
export async function exportExcelTwo(params: any) {
  return request('/api/mediation/exportExcelTwo', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

/**
 * 批量导出前端获取数据接口-江苏银行
 */
export async function exportExcelJsyh(params: any) {
  return request('/api/mediation/exportExcelJsyh', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}


// 一键起查询接口
export async function getQueryDataOne(params: any) {
  return request('/api/yjqs/query_data.one', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
// 一键起诉提交信息接口
export async function getQuerySaveOne(params: any) {
  return request('/api/yjqs/save_data.one', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
// 一键起诉查询诉讼证据材料
export async function getQueryDataTwo(params: any) {
  return request('/api/yjqs/query_data.two', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
// 一键起诉查询当事人信息
export async function getQueryDataThree(params: any) {
  return request('/api/yjqs/query_data.three', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
// 提交一键起诉
export async function getQuerySaveThree(params: any) {
  return request('/api/yjqs/save_data.three', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
// 办结归档反馈法院
export async function caseFinishFeedbackToCourt(params: any) {
  return request('/api/mediation/caseFinishFeedbackToCourt', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 申请延期
export async function applyDelay(params: any) {
  return request('/api/mediation/applyDelay', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 根据组织查找办结方式
export async function findMethodByOrgId() {
  return request('/api/mediation/findMethodByOrgId');
}


// 根据案件id查询当事人信息
export async function getApplication(params: any) {
  return request(`/api/applicant/getApplication?mediationId=${params}`);
}

// 查询cos接口
export async function getNameByIdCard(imageUrls: string, token: string) {
  return request(`/api/file/getNameByIdCard?imageUrls=${imageUrls}&token=${token}`, {
    method: 'POST',
  });
}
// 单个在网状态查询
export async function findZwztByPhoneId(phoneId: any) {
  return request(`/api/mediation/findZwztByPhoneId?phoneId=${phoneId}`, {
    method: 'POST',
  });
}

// 七天内号码网状态查询
export async function findZwztByPhoneIds(phoneIds: any) {
  return request(`/api/mediation/findZwztByPhoneIds?phoneIds=${phoneIds}`, {
    method: 'POST',
  });
}

// 批量网状态查询
export async function findZwztByMrdiationIds(params: any) {
  return request('/api/mediation/findZwztByMrdiationIds', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// 外呼发送短信
export async function sendCallSms(phoneId: any) {
  const formData = new FormData();
  formData.append('phoneId', phoneId);
  return request('/api/sms/sendCallSms', {
    method: 'POST',
    data: formData,
  });
}

/**
 * 逻辑删除案件
 *
 */
export async function delCase(params: any) {
  return request('/api/mediation/delCase', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

/**
 * 增加案件调解记录
 */
export function addMediationRecord(vo: any) {
  return request('/api/mediation/record/add', {
    method: 'POST',
    data: {
      ...vo,
      method: 'post',
    },
  });
}

export function updateEnclosureUrl(fileId: string, mediationId: string) {
  return request('/api/mediation/updateEnclosureUrl', {
    method: 'POST',
    data: {
      fileId,
      mediationId,
    },
  });
}

/**
 * 查询案件的商标权属
 * @param query
 * @param page
 * @param size
 */
export function queryTMOwnership(query: { mediationId: string }, page: number, size: number) {
  return request<BaseResponse<{
    list: TMOwnershipInfo[];
    pagination: { total: number, current: number; pageSize: number };
  }>>('/api/trademark/page/queryTMOwnershipInfo', {
    method: 'POST',
    data: {
      mediationId: query.mediationId,
      page,
      size,
    },
  });
}

/**
 * 向案件中添加商标权属信息
 * @param mediationId
 * @param tmOwnershipInfoVoList
 */
export function addTMOwnership(mediationId: string, tmOwnershipInfoVoList: TMOwnershipInfo[]) {
  return request<BaseResponse<void>>('/api/trademark/addTMOwnershipInfo', {
    method: 'POST',
    data: {
      mediationId,
      tmOwnershipInfoVoList,
    },
  });
}

/**
 * 查询案件的侵权商品信息
 * @param query
 * @param page
 * @param size
 * @returns
 */
export function queryInfringingGoods(query: { mediationId: string }, page: number, size: number) {
  return request<BaseResponse<{
    list: InfringingGoodsInfo[];
    pagination: { total: number, current: number; pageSize: number };
  }>>('/api/infringing/page/queryInfringingGoods', {
    method: 'POST',
    data: {
      mediationId: query.mediationId,
      page,
      size,
    },
  });
}

/**
 * 向案件中添加侵权商品信息
 * @param mediationId
 * @param infringingGoodsInfoVoList
 */
export function addInfringingGoods(mediationId: string, infringingGoodsInfoVoList: InfringingGoodsInfo[]) {
  return request<BaseResponse<void>>('/api/infringing/addInfringingGoods', {
    method: 'POST',
    data: {
      mediationId,
      infringingGoodsInfoVoList,
    },
  });
}

/**
 * 查询原告主张的权属作品情况
 * @param query
 * @param page
 * @param size
 * @returns
 */
export function queryCopyrightWork(query: { mediationId: string }, page: number, size: number) {
  return request<BaseResponse<{
    list: CopyrightWorkInfoVO[];
    pagination: { total: number, current: number; pageSize: number };
  }>>('/api/copyright/page/queryCopyrightWork', {
    method: 'POST',
    data: {
      mediationId: query.mediationId,
      page,
      size,
    },
  });
}

/**
 * 向案件中添加侵权商品信息
 * @param mediationId
 * @param copyrightWorkInfoVoList
 */
export function addCopyrightWork(mediationId: string, copyrightWorkInfoVoList: CopyrightWorkInfoVO[]) {
  return request<BaseResponse<void>>('/api/copyright/addCopyrightWork', {
    method: 'POST',
    data: {
      mediationId,
      copyrightWorkInfoVoList,
    },
  });
}

/**
 * 获取编辑用的案件详情
 * @param id
 */
export async function getEditMediationDetail(mediationId: string) {
  const res = await request(`/api/mediation/get/${mediationId}`, {
    method: 'GET',
  });

  if (res.errCode === ResponseCode.SUCCESS) {
    const tmOwnershipRes = await queryTMOwnership({ mediationId }, 1, 10000);
    const infringingGoodsRes = await queryInfringingGoods({ mediationId }, 1, 10000);

    res.data.tmOwnershipInfoList = tmOwnershipRes.data.list;
    res.data.infringingGoodsInfoList = infringingGoodsRes.data.list;
  }

  return res;
}
