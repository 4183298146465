import { parse } from 'querystring';
import pathRegexp from 'path-to-regexp';
import DateTimeFormat from '@/enum/DateTimeFormat';
import moment from 'moment';
import { MenuType } from '@/models/user';
import axios from 'axios';
/**
 * 表格排序
 * @param propeties
 * @returns {function(*, *): *}
 */
export function compareSortFunction(propeties: any) {
  const compareSort = (rowa: any, rowb: any) => sortFunction(rowa[propeties], rowb[propeties]);
  return compareSort;
}
/**
 *  表格排序方法
 * @param a
 * @param b
 * @returns {number}
 */
export function sortFunction(a: any, b: any) {
  if (!a) {
    a = '';
  }
  if (!b) {
    b = '';
  }
  if ((typeof a) !== 'number') {
    return a.localeCompare(b);
  }
}

/**
 * 数字转中文
 */
export function NoToChinese(num) {
  if (!/^\d*(\.\d*)?$/.test(num)) {
    alert('Number is wrong!');
    return 'Number is wrong!';
  }
  const AA = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  const BB = ['', '十', '百', '千', '万', '亿', '点', ''];
  const a = (`${num}`).replace(/(^0*)/g, '').split('.');
  let k = 0;
  let re = '';
  for (var i = a[0].length - 1; i >= 0; i--) {
    switch (k) {
      case 0:
        re = BB[7] + re;
        break;
      case 4:
        if (!new RegExp(`0{4}\\d{${a[0].length - i - 1}}$`).test(a[0])) re = BB[4] + re;
        break;
      case 8:
        re = BB[5] + re;
        BB[7] = BB[5];
        k = 0;
        break;
    }
    if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) re = AA[0] + re;
    if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
    k++;
  }
  if (a.length > 1) // 加上小数部分(如果有小数部分)
  {
    re += BB[6];
    for (var i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)];
  }
  return re;
}


/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export const isUrl = (path: string): boolean => reg.test(path);

export const getPageQuery = () => parse(window.location.href.split('?')[1]);

/**
 * props.route.routes
 * @param router [{}]
 * @param pathname string
 */
export const getAuthorityFromRouter = <T extends { path: string }>(
  router: T[] = [],
  pathname: string,
): T | undefined => getAuthorityFromServeRouter(router, pathname);

export const formatDatePattern = (date: any, format: string): string => (date ? moment(date).format(format) : '');

export const formatDate = (date: any) => formatDatePattern(date, DateTimeFormat.YYYY_MM_DD);

// eslint-disable-next-line max-len
export const formatDateTime = (date: any) => formatDatePattern(date, DateTimeFormat.YYYY_MM_DD_HH_MM_SS);
export const formatDateTimeStr = (date: any) => formatDatePattern(date, DateTimeFormat.YYYY_MM_DD_HH_MM_SS_STR);

export const isNotEmpty = (obj: any): boolean => obj && obj.length > 0;

export const isEmpty = (obj: any): boolean => !isNotEmpty(obj);

const expireStateObj = {
  0: '',
  1: 'warning-row',
  2: 'error-row',
};

export const getRowClass = (expireState: string | undefined) => (expireState ? expireStateObj[expireState] : '');

let lastStrPermission: string;
let lastPermissions: string[];
let matchPermissionCache: Record<string, boolean> = {};
let permissionCheckCache: Record<string, boolean> = {};

function getPermissions() {
  const permissionStr = localStorage.getItem('permissions') || '[]';

  if (permissionStr === lastStrPermission) {
    return lastPermissions;
  }

  lastStrPermission = permissionStr;
  lastPermissions = JSON.parse(permissionStr);
  matchPermissionCache = {};
  permissionCheckCache = {};

  return lastPermissions;
}

/**
 * 判断权限是否满足要求
 * @param set 需要的权限
 * @param permissions 权限名
 */
function isMatchPermission(set: string, permissions: string[]) {
  if (set in matchPermissionCache) {
    return matchPermissionCache[set];
  }

  let res: boolean;
  // 如果以!开头，则判断不相同
  if (set.startsWith('!')) {
    const acquireName = set.substring(1);

    res = !permissions.includes(acquireName);
  } else {
    res = permissions.includes(set)
  }

  matchPermissionCache[set] = res;

  return res;
}

export const hasPermission = (key: string): boolean => {
  if (!key) {
    return false
  }
  let hasPermission = false
  // console.log(permissionStr)
  const permissions = getPermissions();

  // getPermissions的时候已经检查过缓存是否失效了，这里不需要再检查
  if (key in permissionCheckCache) {
    return permissionCheckCache[key];
  }

  // 分割符要么是|对应只要有一个就行，要么是,对应都要有
  const matchAll = key.includes(',');
  const keys = key.split(/[,|]/g);
  let permissionNum = 0
  for (let j = 0; j < keys.length; j++) {
    if (isMatchPermission(keys[j], permissions)) {
      permissionNum++;
    }
  }

  if ((matchAll && keys.length == permissionNum) || (!matchAll && permissionNum > 0)) {
    hasPermission = true
  }

  permissionCheckCache[key] = hasPermission;
  return hasPermission
}

export const getCodeName = (type: string, code: string): string => {
  const allCodeStr = localStorage.getItem('allCode') || '[]'
  const allCode = JSON.parse(allCodeStr) || []
  const typeArr = allCode[type]
  if (!typeArr) {
    return ''
  }
  let str = '';
  typeArr.map(item => {
    if (item.key === `${code}`) {
      str = item.display_name
    }
  })
  return str
}

const getAuthorityFromServeRouter = <T extends { path: string }>(
  router: T[] = [],
  pathname: string,
): T | undefined => {
  const permissionRoutesStr = localStorage.getItem('PermissionRoute') || '[]'
  const permissionRoutes: string[] = JSON.parse(permissionRoutesStr)

  const authority = permissionRoutes.find(routePath => routePath && pathRegexp(routePath).exec(pathname));

  if (authority) {
    return undefined
  }
  // @ts-ignore
  return { authority: 'admin', path: pathname }
}

/**
 * 向不含prefix的url链接添加上prefix
 * @param url
 */
export function formatPrefixedUrl(url?: string) {
  if (typeof url === 'undefined') {
    return;
  }

  if (url.startsWith('http')) {
    return url;
  }

  // 已经有前缀或相对路径的路由不需要处理
  if (url.startsWith('/home') || url.startsWith('.')) {
    return url;
  }

  if (url.startsWith('/')) {
    return `/home${url}`;
  }

  return `/home/${url}`;
}

export const savePermissionRoute = (menus: MenuType[] = []): void => {
  const permissionRoutes: string[] = []
  convertMenuToRouters(menus, permissionRoutes)
  localStorage.setItem('PermissionRoute', JSON.stringify([...new Set(permissionRoutes)]))
}

const convertMenuToRouters = (menus: MenuType[] = [], permissionRoutes: string[]) => {
  menus.map(({ url, isHide, children }) => {
    permissionRoutes.push(formatPrefixedUrl(url || '/'))
    if (children) {
      convertMenuToRouters(children, permissionRoutes)
    }
  })
}

// 下载文件（可解决跨域下载问题）
export async function downloadFile(fileUrl: string, fileName: string) {
  if (!fileUrl) return;
  if (fileUrl.indexOf('http') < 0) {
    return;
  }
  fileUrl = fileUrl.replace('http://', 'https://')
  const res = await axios({
    method: 'get',
    url: fileUrl,
    responseType: 'blob',
  });
  const newUrl = window.URL.createObjectURL(res.data);
  const a = document.createElement('a');
  a.href = newUrl;
  a.download = fileName;
  a.click();
  a.remove();
  // 在资源下载完成后 可以人工清除createObjectURL 占用的缓存资源
  window.URL.revokeObjectURL(newUrl);
}

/**
 * 获取url上的参数
 * @param key
 */
export function getUrlParam(key: string) {
  const url = new URL(location.href);

  return url.searchParams.get(key);
}

/**
 * 返回登录页的地址
 */
export function getLoginUrl() {
  if (getUrlParam('dsy') === 'true') {
    return '/user/login_dsy';
  }

  return localStorage.getItem('LOGIN_FROM') || "/user/login";
}
