export enum MediationCaseType {
  INTELLECTUAL_PROPERTY_DISPUTES = 1,
  NETWORK_INFRINGEMENT_LIABILITY = 2,
  NETWORK_SERVICE_CONTRACT = 3,
  ONLINE_SHOPPING_CONTRACT = 4,
}

export const MediationCaseTypeLabel = {
  [MediationCaseType.INTELLECTUAL_PROPERTY_DISPUTES]: '涉网知识产权类纠纷',
  [MediationCaseType.NETWORK_INFRINGEMENT_LIABILITY]: '网络侵权责任纠纷',
  [MediationCaseType.NETWORK_SERVICE_CONTRACT]: '网络服务合同纠纷',
  [MediationCaseType.ONLINE_SHOPPING_CONTRACT]: '网络购物合同纠纷',
};
