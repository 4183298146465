export default {
  'menu.dashboard': '工作台',
  'menu.caseEntry': '申请调解',
  'menu.caseEntry.single': '单个申请',
  'menu.caseEntry.batch': '批量申请',
  'menu.caseEntry.saveResult': '保存结果',
  'menu.caseManagement': '案件管理',
  'menu.caseManagement.list': '案件列表',
  'menu.caseManagement.initCheckList': '确认调解列表',
  'menu.caseManagement.mediatorList': '指派调解员列表',
  'menu.caseManagement.mediationNotifyList': '调解通知列表',
  'menu.caseManagement.mediationList': '调解列表',
  'menu.caseManagement.docGenerateList': '文书生成列表',
  'menu.caseManagement.single': '补充录入',
  'menu.caseManagement.profile': '案件详情',
  'menu.caseManagement.mediationRecord': '调解记录',
  'menu.caseManagement.mediationRecordSaveResult': '保存结果',
  'menu.caseManagement.docProfile': '文书详情',
  'menu.mediate': '纸质材料管理',
  'menu.mediate.paperManagement': '材料列表',
  'menu.returnMoneyManagement': '回款管理',
  'menu.returnMoneyManagement.caseList': '回款列表',
  'menu.returnMoneyManagement.caseDetail': '回款明细列表',
  'menu.returnMoneyManagement.notarizationList': '公证管理列表',
  'menu.data': '数据统计',
  'menu.system': '系统管理',
  'menu.system.userList': '用户列表',
  'menu.system.smsTemplateList': '短信模板列表',
  'menu.more-blocks': '更多区块',
  'menu.home': '首页',
  'menu.admin': '管理页',
  'menu.login': '登录',
  'menu.register': '注册',
  'menu.register.result': '注册结果',
  'menu.dashboard.analysis': '分析页',
  'menu.dashboard.monitor': '监控页',
  'menu.dashboard.workplace': '工作台',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': '表单页',
  'menu.form.basic-form': '基础表单',
  'menu.form.step-form': '分步表单',
  'menu.form.step-form.info': '分步表单（填写转账信息）',
  'menu.form.step-form.confirm': '分步表单（确认转账信息）',
  'menu.form.step-form.result': '分步表单（完成）',
  'menu.form.advanced-form': '高级表单',
  'menu.list': '列表页',
  'menu.list.table-list': '查询表格',
  'menu.list.basic-list': '标准列表',
  'menu.list.card-list': '卡片列表',
  'menu.list.search-list': '搜索列表',
  'menu.list.search-list.articles': '搜索列表（文章）',
  'menu.list.search-list.projects': '搜索列表（项目）',
  'menu.list.search-list.applications': '搜索列表（应用）',
  'menu.profile': '详情页',
  'menu.profile.basic': '基础详情页',
  'menu.profile.advanced': '高级详情页',
  'menu.result': '结果页',
  'menu.result.success': '成功页',
  'menu.result.fail': '失败页',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.account': '个人页',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.trigger': '触发报错',
  'menu.account.logout': '退出登录',
  'menu.editor': '图形编辑器',
  'menu.editor.flow': '流程编辑器',
  'menu.editor.mind': '脑图编辑器',
  'menu.editor.koni': '拓扑编辑器',
};
