enum ApiServer {
  // LOCAL = 'http://zhifa.zhangxiaozhang.vaiwan.com',
  // LOCAL = 'http://106.54.155.84:8090',
  LOCAL = '',
  // LOCAL = 'http://101.42.52.13:11003',
  // LOCAL = 'http://mediate.smartlaw.net.cn',
  // LOCAL = 'http://129.211.130.167:8090',
  TEST = '',
  PROD = '',
}

// @ts-ignore
const getApiServer = (): string => ApiServer[process.env.ENV_NAME];

export default getApiServer;
