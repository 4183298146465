import { Effect } from 'dva';
import { Reducer } from 'redux';

import { queryCurrent, getAllCode, query as queryUsers } from '@/services/user';
import { savePermissionRoute } from '@/utils/utils';

export interface CurrentUser {
  id?: string;
  name?: string;
  gender?: string;
  phone?: string;
  loginName?: string;
  employeeMenuTree?:MenuType[],
  permissions?:string[]
}

export interface MenuType {
  id?:string;
  isCache?:string;
  isHide?:string;
  isRoute?:string;
  menuCover?:string;
  menuIcon?:string;
  menuLevel?:string;
  menuName?:string;
  menuRouterName?:string;
  parentId?:string;
  redirectView?:string;
  sequenceCode?:string;
  url?:string;
  children?:MenuType[]

}

export interface UserModelState {
  currentUser?: CurrentUser;
}

export interface UserModelType {
  namespace: 'user';
  state: UserModelState;
  effects: {
    fetch: Effect;
    fetchCurrent: Effect;
  };
  reducers: {
    saveCurrentUser: Reducer<UserModelState>;
    changeNotifyCount: Reducer<UserModelState>;
  };
}

const UserModel: UserModelType = {
  namespace: 'user',

  state: {
    currentUser: {},
  },

  effects: {
    *fetch(_, { call, put }) {
      const response = yield call(queryUsers);
      yield put({
        type: 'save',
        payload: response,
      });
    },
    *fetchCurrent(_, { call, put }) {
      const response = yield call(queryCurrent);
      const allCode = yield call(getAllCode);
      const user = response.data || {}
      const permissions = user.permissions || []
      const employeeMenuTree = user.employeeMenuTree || []
      savePermissionRoute(employeeMenuTree)
      localStorage.setItem('userId', user.id)
      const simpleUser = {
        id: user.id,
        name: user.name,
        userType: user.userType,
        phone: user.phone,
        cardNo: user.cardno || '',
        firmCode: user.firmCode,
      };
      localStorage.setItem('user', JSON.stringify(simpleUser))
      localStorage.setItem('employeeId', user.hzzfEmpId)
      localStorage.setItem('permissions', JSON.stringify(permissions))
      localStorage.setItem('allCode', JSON.stringify(allCode.data))
      yield put({
        type: 'saveCurrentUser',
        payload: response,
      });
      return response;
    },
  },

  reducers: {
    saveCurrentUser(state, action) {
      return {
        ...state,
        currentUser: action.payload.data || {},
      };
    },
    changeNotifyCount(
      state = {
        currentUser: {},
      },
    ) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
        },
      };
    },
  },
};

export default UserModel;
