import request, { BaseResponse } from '@/utils/request';
import { UserMenu, UserSession } from './user.typing';
import ResponseCode from '@/enum/ResponseCode';
import { walk } from '@/utils/menu';
import { MediationCaseType, MediationCaseTypeLabel } from '@/enum/MediationCaseType';

export async function query(): Promise<any> {
  return request('/api/users');
}

function findSingleEntry(tree: UserMenu[]) {
  let res;

  walk(tree, menu => {
    if (menu.url === '/home/caseEntry/single') {
      res = menu;
      return false;
    }
  });

  return res;
}

export async function queryCurrent(): Promise<BaseResponse<UserSession>> {
  const res = await request('/api/user/current');

  if (res.errCode === ResponseCode.SUCCESS) {
    // 将个案申请拆分成案件类型的子菜单
    const singleEntry = findSingleEntry(res.data.employeeMenuTree);

    if (singleEntry) {
      // @ts-ignore
      singleEntry.children = Object.keys(MediationCaseTypeLabel).map(key => {
        res.data.permissions.push(`single_${key}`);
        return {
          url: `/home/caseEntry/single/${key}`,
          menuRouterName: `single_${key}`,
          menuName: MediationCaseTypeLabel[key as unknown as MediationCaseType],
        }
      });
    }
  }

  return res;
}

/**
 * 根据角色编码查询用户列表
 *
 * @param params 查询条件
 */
export async function queryUsersByRoleCode(params: any) {
  return request(`/api/user/getUsersByRoleCode?roleCode=${params.roleCode}`);
}

/**
 * 查询所有syscode
 */
 export async function getAllCode() {
  return request('/api/mediation/getAllCode');
}

/**
 * 根据角色编码查询跨组织用户列表
 *
 * @param params 查询条件
 */
 export async function queryAllUsersByRoleCode(params: any) {
  return request(`/api/user/getUsersAllByRoleCode?roleCode=${params.roleCode}`);
}

/**
 * 返回当前用户的扩展资料
 */
export function getCurrentExtends(): Promise<BaseResponse<{
  name: string;
  idCard: string;
  idCardUrl: string;
  userType: number;
}>> {
  return request('/api/user/currentExtend');
}
