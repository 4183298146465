import request from '@/utils/request';

export async function queryCourtList() {
  return request('/api/court/list');
}

export async function querySmsTemplateList() {
  return request('/api/sms/template/list');
}

/**
 * 只查批量短信模板
 *
 */
 export async function queryBatchSmsTemplateList() {
  return request('/api/sms/template/batchList');
}
// 开始自动外呼
export async function getAutoCall() {
  return request('/api/callTask/autoCall', {
    method: 'POST',
  });
}

// 结束自动外呼
export async function SetupdateTask(id: any) {
  return request(`/api/callTask/updateTask/${id}`, {
    method: 'POST',
  });
}

// 获取一条记录
export async function getFindTaskPhone() {
  return request('/api/callTask/findTaskPhone', {
    method: 'POST',
  });
}
// 获取外呼状态
export async function GetfindCallingByTaskId(id: any) {
  return request(`/api/callTask/findCallingByTaskId/${id}`, {
     method: 'POST',
  });
}


// 获取当前用户拨打状态
export async function getSelectRunTWcTask() {
  return request('/api/callTask/selectRunTWcTask', {
    method: 'POST',
  });
}
