import { Reducer } from 'redux';
import { Effect, Subscription } from 'dva';
import {
  designate,
  mediationInfoList,
  mediationLogList,
  mediationUpdate,
  plaintiffAndDefendantList,
  queryMediation,
  queryMediationAddr,
  querySysCodeList,
  saveMediationAddr,
  savePlan,
  smsPhoneList,
  suitableMediation,
  updateCaseNode,
} from '@/services/mediation';
import { userRegister } from '@/services/login';
import { queryAllUsersByRoleCode, queryUsersByRoleCode } from '@/services/user';
import { delLabel, getCaseListRowInfo, queryLabelByType, repayMoneyAdd, saveLabel } from '@/services/label';
import { queryBatchSmsTemplateList, queryCourtList, querySmsTemplateList } from '@/services/base';

import { SysUser } from '@/entity/SysUser';
import MediationTimeAddr from '@/entity/MediationTimeAddr';
import MediationLabel from '@/entity/MediationLabel';
import { MediationVo } from '@/pages/CaseManagement/CaseProfile/data';
import { queryApplicantByPhoneId, ringUp, saveCallRecord, saveContact } from '@/services/applicant';
import PersonEntity from '@/entity/PersonEntity';
import CallRecord from '@/entity/CallRecord';

export interface GlobalModelState {
  collapsed: boolean;
  users: SysUser[];
  userList:SysUser[];
  userListAll:SysUser[];
  mediationTimeAddrList: MediationTimeAddr[];
  labelList: MediationLabel[];
  labelType: number;
  mediation: Partial<MediationVo>;
  applicant: Partial<PersonEntity>;
  callRecord: CallRecord;
  mediationLogList: any;
  mediationInfoList: any;
  plaintiffAndDefendantList:any;
  smsPhoneList: any;
  courtList: any;
  smsTemplateList: any;
  smsTemplateBatchList: any;
  sysCaseLabelList: any;
}

export interface GlobalModelType {
  namespace: 'global';
  state: GlobalModelState;
  effects: {
    designate: Effect;
    userRegister: Effect;
    mediationUpdate: Effect;
    suitableMediation: Effect;
    querySysCodeList: Effect;
    updateCaseNode: Effect;
    queryUsersByRoleCode: Effect;
    queryAllUsersByRoleCode:Effect;
    queryMediationTimeAddr: Effect;
    saveLabel: Effect;
    delLabel: Effect;
    savePlan: Effect;
    saveMediationAddr: Effect;
    queryMediation: Effect;
    queryApplicantByPhoneId: Effect;
    saveContact: Effect;
    ringUp: Effect;
    saveCallRecord: Effect;
    queryMediationLogList: Effect;
    queryMediationInfoList: Effect;
    queryPlaintiffAndDefendantMediationInfoList:Effect;
    querySmsPhoneList: Effect;
    queryCourtList: Effect;
    querySmsTemplateList: Effect;
    queryBatchSmsTemplateList: Effect;
    getCaseListRowInfo: Effect;
    repayMoneyAdd: Effect;
  };
  reducers: {
    changeLayoutCollapsed: Reducer<GlobalModelState>;
    save: Reducer<GlobalModelState>;
  };
  subscriptions: { setup: Subscription };
}

const GlobalModel: GlobalModelType = {
  namespace: 'global',

  state: {
    collapsed: false,
    users: [],
    userList: [],
    userListAll: [],
    mediationTimeAddrList: [{}],
    labelList: [],
    labelType: -1,
    mediation: {},
    applicant: {},
    callRecord: {},
    mediationLogList: {
      list: [],
      pagination: {},
    },
    mediationInfoList: {
      list: [],
      pagination: {},
    },
    plaintiffAndDefendantList: {
      list: [],
      pagination: {},
    },
    smsPhoneList: [],
    courtList: [],
    smsTemplateList: [],
    smsTemplateBatchList: [],
    sysCaseLabelList: [],
  },

  effects: {
    *suitableMediation({ payload }, { call }) {
      const response = yield call(suitableMediation, payload);
      return response.errCode;
    },
    *querySysCodeList({ payload }, { call, put }) {
      const response = yield call(querySysCodeList, payload);
      yield put({
        type: 'save',
        payload: {
          sysCaseLabelList: response.data || [],
        },
      });
      return response.data;
    },
    *updateCaseNode({ payload }, { call }) {
      const response = yield call(updateCaseNode, payload);
      return response.errCode;
    },
    *mediationUpdate({ payload }, { call }) {
      const response = yield call(mediationUpdate, payload);
      return response;
    },
    *queryUsersByRoleCode({ payload }, { call, put }) {
      const response = yield call(queryUsersByRoleCode, payload);
      // 运营
      if (payload.roleCode === '002') {
        yield put({
          type: 'save',
          payload: {
            users: response.data || [],
          },
        });
      } else {
        // 调解员
        yield put({
          type: 'save',
          payload: {
            userList: response.data || [],
          },
        });
      }
    },
    *queryAllUsersByRoleCode({ payload }, { call, put }) {
      const response = yield call(queryAllUsersByRoleCode, payload);
        yield put({
          type: 'save',
          payload: {
            userListAll: response.data || [],
          },
        });
    },
    *queryMediationTimeAddr({ payload }, { call, put }) {
      const response = yield call(queryMediationAddr, payload);
      yield put({
        type: 'save',
        payload: {
          mediationTimeAddrList: response.data || [{}],
        },
      });
      return response;
    },
    *saveLabel({ payload }, { call }) {
      const response = yield call(saveLabel, payload);
      return response.errCode;
    },
    *delLabel({ payload }, { call }) {
      const response = yield call(delLabel, payload);
      return response.errCode;
    },
    *designate({ payload }, { call }) {
      const response = yield call(designate, payload);
      return response.errCode;
    },
    *userRegister({ payload }, { call }) {
      const response = yield call(userRegister, payload);
      return response.errCode;
    },
    *savePlan({ payload }, { call }) {
      const response = yield call(savePlan, payload);
      return response.errCode;
    },
    *saveMediationAddr({ payload }, { call }) {
      return yield call(saveMediationAddr, payload);
    },
    *queryMediation({ payload }, { call, put }) {
      const response = yield call(queryMediation, payload);
      yield put({
        type: 'save',
        payload: {
          mediation: response.data,
        },
      });
    },
    *queryApplicantByPhoneId({ payload }, { call, put }) {
      const response = yield call(queryApplicantByPhoneId, payload);
      yield put({
        type: 'save',
        payload: {
          applicant: response.data,
        },
      });
    },
    *saveContact({ payload }, { call }) {
      const response = yield call(saveContact, payload);
      return response.errCode;
    },
    *ringUp({ payload }, { call }) {
      return yield call(ringUp, payload);
    },
    *saveCallRecord({ payload }, { call }) {
      return yield call(saveCallRecord, payload);
    },
    *queryMediationLogList({ payload }, { call, put }) {
      const response = yield call(mediationLogList, payload);
      yield put({
        type: 'save',
        payload: {
          mediationLogList: response.data,
        },
      });
    },
    *queryMediationInfoList({ payload }, { call, put }) {
      const response = yield call(mediationInfoList, payload);
      yield put({
        type: 'save',
        payload: {
          mediationInfoList: response.data,
        },
      });
    },
    *queryPlaintiffAndDefendantMediationInfoList({ mediationId }, { call, put }) {
      const response = yield call(plaintiffAndDefendantList, mediationId);
      yield put({
        type: 'save',
        payload: {
          plaintiffAndDefendantList: response.data,
        },
      });
    },

    *querySmsPhoneList({ payload }, { call, put }) {
      const response = yield call(smsPhoneList, payload);
      yield put({
        type: 'save',
        payload: {
          smsPhoneList: response,
        },
      });
    },
    *queryCourtList({ payload }, { call, put }) {
      const response = yield call(queryCourtList, payload);
      yield put({
        type: 'save',
        payload: {
          courtList: response.data,
        },
      });
    },
    *querySmsTemplateList({ payload }, { call, put }) {
      const response = yield call(querySmsTemplateList, payload);
      yield put({
        type: 'save',
        payload: {
          smsTemplateList: response.data,
        },
      });
    },
    *queryBatchSmsTemplateList({ payload }, { call, put }) {
      const response = yield call(queryBatchSmsTemplateList, payload);
      yield put({
        type: 'save',
        payload: {
          smsTemplateBatchList: response.data,
        },
      });
    },
    *getCaseListRowInfo({ payload }, { call }) {
      const response = yield call(getCaseListRowInfo, payload);
      return response;
    },
    *repayMoneyAdd({ payload }, { call }) {
      const response = yield call(repayMoneyAdd, payload);
      return response;
    },
  },

  reducers: {
    changeLayoutCollapsed(state = { collapsed: true }, { payload }): GlobalModelState {
      return {
        ...state,
        collapsed: payload,
      };
    },
    save(state, { payload }): GlobalModelState {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default GlobalModel;
