/* eslint-disable max-len */
export interface ShareAsyncOption {
  /**
   * 是否进行缓存
   */
  cache?: boolean;
  /**
   * 哈希函数
   */
  hashKey?: (args: any[]) => string;
}

/**
 * 对函数参数进行hash
 * @param {*} args
 */
function defaultHash(args: any) {
  return JSON.stringify(args);
}

/**
 * 对一个async函数进行缓存，保证多次多处调用都会拿到同一次调用的结果
 * 主要是方便某些组件存在多个实例的情况下，可以不用状态管理包，直接对结果进行缓存
 */
export default function share<T extends Array<any>, R = any>(asyncFunc: (...args: T) => Promise<R>, context?: any, option = {} as ShareAsyncOption) {
  const {
    // eslint-disable-next-line line-comment-position, no-inline-comments
    cache = true, // 默认缓存数据
    // eslint-disable-next-line line-comment-position, no-inline-comments
    hashKey = defaultHash, // 允许传入单独的hash函数，可以缓解key过长的情况
  } = option;

  const promiseMap: Record<string, any> = {};
  const cacheMap: Record<string, any> = {};

  /**
   * 把请求队列里的请求都干掉
   * @param {*} key
   * @param {*} type
   * @param {*} res
   */
  function finishPromises(key: string, type: string, res: any) {
    const promiseList = promiseMap[key];
    promiseList.forEach((promise: any) => {
      promise[type](res);
    });

    delete promiseMap[key];
  }

  return async (...args: T): Promise<R> => {
    const key = hashKey(args);

    /**
     * 有缓存直接命中
     */
    if (cache && cacheMap[key]) {
      return cacheMap[key];
    }

    if (promiseMap[key]) {
      return new Promise((resolve, reject) => {
        promiseMap[key].push({ resolve, reject });
      });
    }

    promiseMap[key] = [];
    try {
      const result = await asyncFunc.apply(context, args);
      if (cache) {
        // eslint-disable-next-line require-atomic-updates
        cacheMap[key] = result;
      }
      finishPromises(key, "resolve", result);
      return result;
    } catch (err) {
      finishPromises(key, "reject", err);
      throw err;
    }
  };
}
