import { UserMenu } from '@/services/user.typing';

/**
 * 遍历菜单树
 * @param menus
 * @param callback
 */
export function walk(menus: UserMenu[], callback: (menu: UserMenu) => false | undefined) {
  for (let i = 0; i < menus.length; i++) {
    const res = callback(menus[i]);

    if (res === false) { // 返回false则打断遍历
      return;
    }

    if (menus[i].children) {
      walk(menus[i].children!, callback);
    }
  }
}
