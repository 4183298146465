import request from '@/utils/request';

export async function queryApplicantByPhoneId(params: any) {
  return request('/api/applicant/contactId', {
    params,
  });
}

export async function saveContact(params: any) {
  return request('/api/applicant/saveContact', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function ringUp(params: any) {
  return request('/api/call/record/ringUp', {
    params,
  });
}

export async function queryCallRecord(params: any) {
  return request(`/api/call/record/info/${params.id}`);
}

export async function saveCallRecord(params: any) {
  return request('/api/call/record/add', {
    method: 'post',
    data: {
      ...params,
    },
  });
}


// 根据当事人id查询地址,电话  邮箱
export async function getFindByApplicant(params: any) {
  return request('/api/applicant/findByApplicantId', {
    method: 'get',
    params,
  });
}

export async function queryCallRecordList(params: any) {
  return request('/api/call/record/page/query', {
    method: 'post',
    data: {
      ...params,
    },
  });
}
