import request from '@/utils/request';
import MediationLabel from '@/entity/MediationLabel';
import share from '@/utils/share';

export async function saveLabel(params: MediationLabel) {
  return request('/api/label/add', {
    method: 'POST',
    data: params,
  });
}

export async function delLabel(params: any) {
  return request('/api/label/del', {
    method: 'POST',
    params,
  });
}

export const queryLabelByType = share(async (params: any) => (
  request('/api/label/getByType', {
    method: 'get',
    params,
  })
))

export async function getCaseListRowInfo(data: any) {
  return request('/api/repayment/getInfo', {
    method: 'POST',
    data,
  });
}
export async function repayMoneyAdd(data: any) {
  return request('/api/repayment/add', {
    method: 'POST',
    data,
  });
}
